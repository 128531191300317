<template>
    <div class="page-wraper">
        <!-- HEADER START -->
        <header-layout class-name='header-style-light'></header-layout>
        <!-- HEADER END -->

        <!-- CONTENT START -->
        <div class="page-content">
            <div class="section-full p-t120 p-b90 site-bg-gray bg-cover overlay-wraper"
                style="background-image:url(assets/images/background/bg-2.jpg)">
                <div class="overlay-main site-bg-primary opacity-01"></div>
                <div class="container">
                    <div class="section-content" id="parallax-content">
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-5 col-md-12 m-b30">
                                <!--Block one-->
                                <div class="blog-post twm-blog-post-2-outer">
                                    <div class="wt-post-media">
                                        <a href="javascript:void(0)"><img src="assets/images/recrut.jpg" alt=""></a>
                                    </div>
                                    <div class="wt-post-info">
                                        <div class="wt-post-meta ">
                                            <ul>
                                                <li class="post-date w-100">Maitrisez l'utilisation
                                                    professionnelle de
                                                    l'IA</li>
                                            </ul>
                                        </div>

                                        <div class="wt-post-title ">
                                            <h4 class="post-title text-white">
                                                Cette session de formation a pour objectif de vous fournir une
                                                compréhension approfondie de l'intelligence artificielle (IA) et de son
                                                utilisation dans la vie quotidienne.
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="twm-testimonial-page8-left mt-4">
                                    <div class="testimonial-outline-text-small bounce2">
                                        <span>Exploiter la puissance d'internet grâce à l'utilsation de l'IA !</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">

                                <div class="twm-blog-post-wrap-right">
                                    <!--Block two-->
                                    <div class="blog-post twm-blog-post-1-outer shadow-none  m-b20">
                                        <div class="wt-post-info">

                                            <div class="wt-post-meta ">
                                                <ul>
                                                    <li class="post-date">Du Vendredi 12 Juillet au Mardi 16 Juillet
                                                    </li>
                                                    <li class="post-author"> De <a href="javascript:void(0)">16h30 à
                                                            20h</a></li>
                                                </ul>
                                            </div>

                                            <div class="wt-post-title ">
                                                <h4 class="post-title">
                                                    <a href="javascript:void(0)">Dispensation du cours et des ateliers
                                                        pratiques</a>
                                                </h4>
                                            </div>
                                            <div class="wt-post-title ">
                                                <p class="post-title">
                                                    <i class="fa fa-map-marker-alt text-info"></i><small>
                                                        03, Bismark, Golf, Gombe Kinshasa, Ref. Immeuble Startup en face
                                                        du Terrain maman Yemo à côté de la maison Koffi et securico !
                                                    </small>
                                                </p>
                                            </div>

                                        </div>
                                    </div>

                                    <!--Block three-->
                                    <div class="blog-post twm-blog-post-1-outer shadow-none  m-b20">
                                        <div class="wt-post-info">

                                            <div class="wt-post-meta ">
                                                <ul>
                                                    <li class="post-date">Dimache 21 Juillet</li>
                                                    <li class="post-author"> à <a href="javascript:void(0)">13h</a></li>
                                                </ul>
                                            </div>

                                            <div class="wt-post-title ">
                                                <h4 class="post-title">
                                                    <a href="javascript:void(0)">Test et remise de brevet !</a>
                                                </h4>
                                                <div class="wt-post-title ">
                                                    <p class="post-title">
                                                        <i class="fa fa-map-marker-alt text-info"></i><small>
                                                            03, Bismark, Golf, Gombe Kinshasa, Ref. Immeuble Startup en
                                                            face
                                                            du Terrain maman Yemo à côté de la maison Koffi et securico
                                                            !
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="twm-right-section-panel-wrap2">
                                    <!--Filter Short By-->
                                    <div class="twm-right-section-panel site-bg-primary">

                                        <!--Basic Information-->
                                        <div class="panel panel-default">
                                            <div class="panel-heading wt-panel-heading p-a20">
                                                <h4 class="panel-tittle m-a0">Inscription</h4>
                                                <p class="mt-2 mb-0">Inscrivez-vous pour ne rien rater !</p>
                                            </div>
                                            <div class="panel-body wt-panel-body p-a20 ">
                                                <div class="twm-tabs-style-1">
                                                    <form @submit.prevent="createCandidate" class="row">
                                                        <div class="col-xl-6 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Nom <sup class="text-danger">*</sup></label>
                                                                <div class="ls-inputicon-box">
                                                                    <input class="form-control" name="company_name"
                                                                        type="text" v-model="form.nom"
                                                                        placeholder="Saisir votre nom..." required>
                                                                    <i class="fs-input-icon fa fa-user "></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Prénom <sup class="text-danger">*</sup></label>
                                                                <div class="ls-inputicon-box">
                                                                    <input v-model="form.prenom" class="form-control"
                                                                        name="company_name" type="text"
                                                                        placeholder="Saisir votre prenom..." required>
                                                                    <i class="fs-input-icon fa fa-user "></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Email <sup class="text-danger">*</sup></label>
                                                                <div class="ls-inputicon-box">
                                                                    <input v-model="form.email" class="form-control"
                                                                        name="company_Email" type="email"
                                                                        placeholder="deli@example.com">
                                                                    <i class="fs-input-icon fas fa-at"></i>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Téléphone <sup
                                                                        class="text-danger">*</sup></label>
                                                                <div class="ls-inputicon-box">
                                                                    <input v-model="form.telephone" class="form-control"
                                                                        name="company_phone" type="text"
                                                                        placeholder="+(243) xx-xx-xxxx" required>
                                                                    <i class="fs-input-icon fa fa-phone-alt"></i>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Emploi actuel(<small>optionnel</small>)</label>
                                                                <div class="ls-inputicon-box">
                                                                    <input v-model="form.current_job"
                                                                        class="form-control" name="company_phone"
                                                                        type="text"
                                                                        placeholder="Saisir votre emploi actuel...ex: Agent commercial">
                                                                    <i class="fs-input-icon fa fa-briefcase"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Centres
                                                                    d'intérêt(<small>optionnel</small>)</label>
                                                                <div class="ls-inputicon-box">
                                                                    <Select class="form-control" v-model="form.hobbie">
                                                                        <option value="" selected hidden
                                                                            class="text-muted">
                                                                            <small>Sélectionnez votre centre
                                                                                d'intérêt...</small>
                                                                        </option>
                                                                        <option v-for="(item, index) in hobbies"
                                                                            :key="index" :value="item.toString()">
                                                                            {{ item.toString() }}
                                                                        </option>
                                                                    </Select>
                                                                    <i class="fs-input-icon fa fa-people-arrows"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Ville <sup class="text-danger">*</sup></label>
                                                                <div class="ls-inputicon-box">
                                                                    <input v-model="form.ville" class="form-control"
                                                                        name="company_phone" type="text"
                                                                        placeholder="Saisir votre le nom de la ville..."
                                                                        required>
                                                                    <i class="fs-input-icon fa fa-map-marked"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Adresse(<small>Optionnelle</small>)</label>
                                                                <div class="ls-inputicon-box">
                                                                    <input v-model="form.adresse" class="form-control"
                                                                        name="company_phone" type="text"
                                                                        placeholder="Saisir votre adresse">
                                                                    <i class="fs-input-icon fa fa-map-marker-alt"></i>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-12 col-lg-12">
                                                            <div class="form-group">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="flexRadioDefault" id="flexRadioDefault2"
                                                                    checked="" v-model="accepted" required>
                                                                <label class="form-check-label" for="flexRadioDefault2">
                                                                    J'accepte les Conditions Générales d'Utilisation et
                                                                    la Politique de Confidentialité. <small><a
                                                                            class="site-text-primary"
                                                                            href="javascript:void(0);"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#privacyModal">Lire les
                                                                            conditions</a></small>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-12 col-lg-12 col-md-12">
                                                            <div class="text-left">
                                                                <button type="submit"
                                                                    :disabled="formLoading || !accepted"
                                                                    class="site-button">S'inscrire
                                                                    maintenant <svg v-if="formLoading" width="24"
                                                                        fill="#FFFFFF" height="24" viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <circle class="spinner_S1WN" cx="4" cy="12"
                                                                            r="3" />
                                                                        <circle class="spinner_S1WN spinner_Km9P"
                                                                            cx="12" cy="12" r="3" />
                                                                        <circle class="spinner_S1WN spinner_JApP"
                                                                            cx="20" cy="12" r="3" />
                                                                    </svg> </button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- CONTENT END -->

        <!-- FOOTER START -->
        <footer-layout></footer-layout>
        <!-- FOOTER END -->
    </div>
    <div class="modal fade" id="privacyModal" tabindex="-1" aria-labelledby="privacyModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="privacyModalLabel">Règles et Politique de Confidentialité</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-2">
                    <div class="mb-3">
                        <h2>Introduction</h2>
                        <p>Bienvenue sur <a href="http://mosala.site">mosala.site</a> . Nous nous engageons à protéger
                            votre vie privée et à respecter
                            vos droits en matière de protection des données personnelles. Cette politique de
                            confidentialité décrit comment nous recueillons, utilisons, et protégeons vos informations
                            personnelles.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Collecte des Informations</h2>
                        <p>Nous pouvons collecter les informations suivantes :</p>
                        <ul>
                            <li>Informations personnelles : nom, adresse e-mail, numéro de téléphone, adresse physique.
                            </li>
                            <li>Informations professionnelles : CV, lettres de motivation, diplômes, certifications,
                                expériences professionnelles.</li>
                            <li>Informations de navigation : adresse IP, type de navigateur, pages visitées, durée de la
                                visite.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Utilisation des Informations</h2>
                        <p>Les informations collectées peuvent être utilisées pour :</p>
                        <ul>
                            <li>Faciliter le processus de recrutement et de candidature.</li>
                            <li>Communiquer avec les utilisateurs concernant leurs candidatures et offres d'emploi.</li>
                            <li>Améliorer nos services et personnaliser l'expérience utilisateur.</li>
                            <li>Respecter nos obligations légales et réglementaires.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Partage des Informations</h2>
                        <p>Nous pouvons partager vos informations avec :</p>
                        <ul>
                            <li>Employeurs : pour faciliter le processus de recrutement.</li>
                            <li>Prestataires de services : pour fournir des services en notre nom (ex : hébergement de
                                site, analyses).</li>
                            <li>Autorités légales : lorsque requis par la loi.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Sécurité des Informations</h2>
                        <p>Nous prenons des mesures de sécurité appropriées pour protéger vos informations personnelles
                            contre l'accès non autorisé, l'altération, la divulgation ou la destruction.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Vos Droits</h2>
                        <p>Vous avez le droit de :</p>
                        <ul>
                            <li>Accéder à vos informations personnelles.</li>
                            <li>Demander la correction ou la suppression de vos informations personnelles.</li>
                            <li>Vous opposer au traitement de vos informations personnelles.</li>
                            <li>Retirer votre consentement à tout moment.</li>
                        </ul>
                        <p>Pour exercer ces droits, veuillez nous contacter à [Adresse e-mail de Contact].</p>
                    </div>

                    <div class="mb-3">
                        <h2>Cookies</h2>
                        <p>Notre site utilise des cookies pour améliorer l'expérience utilisateur. Vous pouvez contrôler
                            l'utilisation des cookies via les paramètres de votre navigateur.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Modifications de la Politique de Confidentialité</h2>
                        <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment.
                            Les modifications seront publiées sur cette page avec une date de mise à jour.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Contact</h2>
                        <p>Pour toute question ou préoccupation concernant cette politique de confidentialité, veuillez
                            nous contacter à :</p>
                        <p>Millenium Horizon RD Congo<br>
                            03, Bismark, Golf, Gombe Kinshasa<br>
                            contact@milleniumhorizon.com<br>
                            +(243) 83 30 00 425</p>
                    </div>

                    <h1>Règles du Site</h1>

                    <div class="mb-3">
                        <h2>Acceptation des Conditions</h2>
                        <p>En utilisant notre site, vous acceptez les présentes conditions d'utilisation. Si vous
                            n'acceptez pas ces conditions, veuillez ne pas utiliser notre site.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Utilisation du Site</h2>
                        <ul>
                            <li>Utilisation légale : Vous vous engagez à utiliser notre site de manière légale et
                                éthique.</li>
                            <li>Contenu : Vous ne publierez pas de contenu diffamatoire, offensant, ou illégal.</li>
                            <li>Respect de la Vie Privée : Vous respecterez la vie privée des autres utilisateurs.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Inscription et Compte</h2>
                        <ul>
                            <li>Informations Véridiques : Vous fournirez des informations exactes lors de l'inscription.
                            </li>
                            <li>Sécurité du Compte : Vous êtes responsable de la sécurité de votre compte et de la
                                confidentialité de votre mot de passe.</li>
                            <li>Utilisation Unique : Un compte est destiné à un seul utilisateur. Vous ne partagerez pas
                                votre compte avec d'autres personnes.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Contenu Utilisateur</h2>
                        <ul>
                            <li>Responsabilité : Vous êtes responsable du contenu que vous publiez sur le site.</li>
                            <li>Propriété : Vous garantissez que vous possédez les droits sur le contenu que vous
                                publiez ou que vous avez l'autorisation de le faire.</li>
                        </ul>
                    </div>

                    <div class="mb-3">
                        <h2>Limitation de Responsabilité</h2>
                        <p>Nous ne sommes pas responsables des dommages directs ou indirects résultant de l'utilisation
                            du site.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Résiliation</h2>
                        <p>Nous nous réservons le droit de suspendre ou de résilier votre compte à tout moment, sans
                            préavis, en cas de violation des présentes conditions d'utilisation.</p>
                    </div>

                    <div class="mb-3">
                        <h2>Lois Applicables</h2>
                        <p>Ces conditions d'utilisation sont régies par les lois en vigueur en République Démocratique
                            du Congo. En cas de
                            litige, les tribunaux de la ville de Kinshasa seront compétents.</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderLayout from '../layouts/header.layout';
import FooterLayout from '../layouts/footer.layout';
import axios from "axios";
export default {
    name: 'HomePage',
    components: {
        HeaderLayout,
        FooterLayout
    },
    data() {
        return {
            form: {
                nom: '',
                prenom: '',
                adresse: '',
                email: '',
                telephone: '',
                current_job: '',
                ville: '',
                hobbie: ''
            },
            formLoading: false,
            accepted: false,
            hobbies: [
                "Lecture",
                "Voyages",
                "Cuisine",
                "Photographie",
                "Journalisme",
                "Infographie",
                "Nouvelle Technologie",
                "Electronique",
                "Sports",
                "Agriculture",
                "Musique",
                "Art",
                "Architecture",
                "Écriture",
                "Jeux vidéo",
                "Droit",
                "Autre..."
            ]
        }
    },

    mounted() {
        if ($('.sticky-header').length) {
            var sticky = new Waypoint.Sticky({
                element: jQuery('.sticky-header')
            });
        }
    },

    methods: {
        createCandidate(e) {
            this.formLoading = true;
            axios.post("https://mosala.bakend.milleniumhorizon.com/api/formation.save.candidate", this.form
            ).then((result) => {
                this.formLoading = false;
                if (result.data.errors !== undefined) {
                    Swal({
                        icon: "warning",
                        text: result.data.errors.toString(),
                        placement: 'bottom-end',
                        timer: 3000,
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                    return;
                }
                if (result.data.status !== undefined) {
                    Swal({
                        icon: 'success',
                        title: 'Effectué avec succès !',
                        text: 'Votre candidature a été soumise avec succès ! Nous vous remercions pour votre intérêt. Vous serez notifié(e) dans les plus brefs délais. Merci pour votre patience. ',
                        showConfirmButton: false,
                        showCancelButton: false,
                        timer: 5000
                    });
                    this.cleanField();
                }
            }).catch((err) => {
                this.isLoading = false
                console.error(err);
                Swal({
                    title: 'Echec de traitement de la requête !',
                    toast: true,
                    icon: 'warning',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000
                });
            })
        },

        cleanField() {
            this.form.nom = '';
            this.form.prenom = '';
            this.form.email = '';
            this.form.telephone = '';
            this.form.adresse = '';
            this.form.current_job = '';
            this.form.ville = '';
            this.form.hobbie = '';
        }
    },

}
</script>