<template>
    <div class="page-wraper">
        <!-- HEADER START -->
        <header-layout></header-layout>
        <!-- HEADER END -->

        <!-- CONTENT START -->
        <div class="page-content">

            <!--Banner Start-->
            <div class="twm-home-6-banner-section">
                <div class="container">
                    <div class="row">
                        <!--Left Section-->
                        <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="twm-bnr-left-section">
                                <div class="twm-bnr-title-small">
                                    Restez connecté pour obtenir un emploi à venir avec
                                    <span class="site-text-primary">Mosala<sup class="text-black">.site</sup></span>
                                </div>
                                <div class="twm-bnr-title-large" data-aos="fade-down">
                                    Trouvez votre carrière pour une vie meilleure chez <span
                                        class="site-text-white">Mosala</span><sup>.site</sup></div>
                                <div class="twm-bnr-discription">
                                    Explorez tous les postes professionnels les plus passionnants en fonction de votre
                                    intérêt et de votre
                                    spécialisation. l'emploi de vos rêves vous attend.
                                </div>
                            </div>
                        </div>

                        <!--right Section-->
                        <div class="col-xl-6 col-lg-6 col-md-12 twm-bnr-right-section">
                            <div class="twm-bnr-right-content">
                                <div class="bnr-media">
                                    <img src="assets/images/home-12/banner-bg/job-men.png" alt="#">
                                </div>
                                <div class="bnr-bg-circle">
                                    <span></span>

                                </div>
                                <div class="bnr-bg-icons">
                                    <div class="icon-plus1 bounce"><i class="fa fa-plus"></i></div>
                                    <div class="icon-plus2 bounce2"><i class="fa fa-plus"></i></div>
                                    <div class="icon-plus3 bounce"><i class="fa fa-plus"></i></div>
                                    <div class="icon-ring1 bounce2"></div>
                                    <div class="icon-ring2 bounce"></div>
                                    <div class="icon-ring3 bounce2"></div>

                                    <div class="bnr-block-1 bounce">
                                        <div class="bnr-block-1-content">
                                            <div class="media"><img src="assets/images/home-6/cv-icon.png" alt=""></div>
                                            <h3 class="title">Charger votre CV</h3>
                                        </div>
                                    </div>
                                    <div class="bnr-block-2 bounce2">

                                        <div class="bnr-block-2-content">
                                            <div class="bnr-block-2-bag">
                                                <span><img src="assets/images/home-6/bag.png" alt=""></span>
                                            </div>
                                            <div class="bnr-block-2-content-top">
                                                <h3 class="title">25 k</h3>
                                                <div class="media"><img src="assets/images/home-6/graph-icon.png" alt="">
                                                </div>
                                            </div>
                                            <div class="bnr-block-2-content-bottom">Les gens ont été embauchés</div>
                                        </div>
                                    </div>
                                    <div class="bnr-block-3 bounce">
                                        <img src="assets/images/home-6/pdf-file.png" alt="#">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!--Banner End-->

            <!-- HOW IT WORK SECTION START -->
            <div style="background-color: #e2ebfc;" class="section-full p-t100 p-b90 site-bg-white twm-how-it-work-area2">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-12" data-aos="fade-up">
                            <!-- TITLE START-->
                            <div class="section-head left wt-small-separator-outer">
                                <div class="wt-small-separator site-text-primary">
                                    <div>Comment ça marche ?</div>
                                </div>
                                <h2 class="wt-title">Suivez nos étapes, nous vous aiderons.</h2>

                            </div>
                            <ul class="description-list">
                                <li>
                                    <i class="feather-check"></i> Sélectionnez un ou plusieurs domaine;
                                </li>
                                <li>
                                    <i class="feather-check"></i> Cliquez sur suivant;
                                </li>
                                <li>
                                    <i class="feather-check"></i> Remplissez tous les champs requis;
                                </li>
                                <li>
                                    <i class="feather-check"></i> Soumettez vos informations.
                                </li>

                            </ul>
                            <!-- TITLE END-->
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="twm-w-process-steps-2-wrap">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="twm-w-process-steps-2" data-aos="flip-left">
                                            <div class="twm-w-pro-top bg-clr-sky-light bg-sky-light-shadow">
                                                <span class="twm-large-number text-clr-sky">01</span>
                                                <div class="twm-media">
                                                    <span><img src="assets/images/work-process/icon1.png"
                                                            alt="icon1"></span>
                                                </div>
                                                <h4 class="twm-title"> Sélectionnez un ou plusieurs domaine</h4>

                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="twm-w-process-steps-2" data-aos="flip-right">
                                            <div class="twm-w-pro-top bg-clr-yellow-light bg-yellow-light-shadow">
                                                <span class="twm-large-number text-clr-yellow">02</span>
                                                <div class="twm-media">
                                                    <span><img src="assets/images/work-process/icon4.png"
                                                            alt="icon1"></span>
                                                </div>
                                                <h4 class="twm-title">Cliquez sur le bouton <strong>Suivant</strong></h4>

                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="twm-w-process-steps-2" data-aos="flip-left">
                                            <div class="twm-w-pro-top bg-clr-pink-light bg-pink-light-shadow">
                                                <span class="twm-large-number text-clr-pink">03</span>
                                                <div class="twm-media">
                                                    <span><img src="assets/images/work-process/icon3.png"
                                                            alt="icon1"></span>
                                                </div>
                                                <h4 class="twm-title">Remplissez tous les champs requis</h4>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6">
                                        <div class="twm-w-process-steps-2" data-aos="flip-right">
                                            <div class="twm-w-pro-top bg-clr-green-light bg-clr-light-shadow">
                                                <span class="twm-large-number text-clr-green">04</span>
                                                <div class="twm-media">
                                                    <span><img src="assets/images/work-process/icon3.png"
                                                            alt="icon1"></span>
                                                </div>
                                                <h4 class="twm-title">Soumettez vos informations</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="twm-how-it-work-section">

                    </div>
                </div>

            </div>
            <div class="scroll-down">
                <svg class="scroll-down-icon" width="40px" height="40px" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 11L12 17L10.25 15.5M5 11L7.33333 13" stroke="#1967d2" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5 7L12 13L13.75 11.5M19 7L16.6667 9" stroke="#002860" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>

            <!-- HOW IT WORK SECTION END -->

            <!-- JOBS CATEGORIES SECTION START -->
            <div class="section-full p-t100 p-b90 site-bg-white twm-job-categories-hpage-6-area" id="recrut">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer">
                    <div class="wt-small-separator site-text-primary">
                        <div>Emplois disponibles aujourd'hui</div>
                    </div>
                    <h2 class="wt-title">Sélectionnez vos domaines pour être formé et recruté</h2>
                </div>
                <!-- TITLE END-->

                <div class="container">
                    <div class="twm-job-cat-hpage-6-wrap">
                        <div class="job-cat-block-hpage-6-section m-b30">
                            <div class="row">
                                <!-- COLUMNS 1 -->
                                <div class="col-md-3 col-6" v-for="(item, index) in domaines" :key="index">
                                    <div class="job-cat-block-hpage-6 m-b30" :class="item.actived ? 'active' : ''">
                                        <label class="costum-checkbox">
                                            <input type="checkbox" style="visibility: hidden;" :checked="item.actived"
                                                @change="onSelectedChanged($event, item)">
                                            <span class="checkmark"><i class="fas fa-check"></i></span>
                                        </label>
                                        <div class="twm-media">
                                            <i :class="item.icon"></i>
                                        </div>
                                        <div class="twm-content">
                                            <a href="javascript:void(0)">{{
                                                item.title
                                            }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="text-center job-categories-btn">
                            <a href="#" class="site-button">Suivant <i class="feather-arrow-right"></i></a>
                        </div> -->

                    </div>

                </div>

            </div>
            <!-- JOBS CATEGORIES SECTION END -->

            <!-- SUBSCRIBE SECTION START -->
            <div class="section-full twm-hpage-6-subs-wrap bg-cover h-page6-getjobs-wrap"
                style="background-image: url(images/home-6/subscribe-bg.jpg)">
                <div class="h-page6-client-slider-outer">
                    <div class="container">
                        <div class="h-page6-client-slider">
                            <div class="row">
                                <div class="col-xl-4 col-lg-12">
                                    <div class="h-page-6-client-slide-title">
                                        <span class="site-text-primary">Nos partenaires</span> qui vous recrutent
                                    </div>
                                </div>

                                <div class="col-xl-8 col-lg-12">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <a href="https://fiscosarlu.com" target="_blank">
                                                <img data-aos="zoom-in" src="assets/images/partenaires/logofisco.png"
                                                    class="img-fluid" alt="fisco logo">
                                            </a>
                                            <!-- <span>Fiduciaire</span> -->
                                        </div>
                                        <div class="col-md-3">
                                            <a href="https://castornetworks.com">
                                                <img data-aos="zoom-in" src="assets/images/partenaires/castor.jpg"
                                                    class="img-fluid" alt="castor network logo">

                                            </a>
                                            <!-- <span>Fournisseur d'accès internet</span> -->
                                        </div>
                                        <div class="col-md-3">
                                            <a href="https://hscongo.net" target="_blank">
                                                <img data-aos="zoom-in" src="assets/images/partenaires/hyper.jpeg"
                                                    class="img-fluid" style="height: 80px;" alt="hyper security logo">
                                            </a>
                                            <!-- <span>Gardiennage</span> -->
                                        </div>
                                        <div class="col-md-3">
                                            <a href="https://milleniumhorizon.com" target="_blank">
                                                <img data-aos="zoom-in" src="assets/images/partenaires/capture.PNG"
                                                    class="img-fluid" alt="millenium horizon logo">
                                            </a>
                                            <!-- <span>Sécurité éléctronique & developpement systèmes</span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">

                    <div class="section-content">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="twm-hpage-6-getintouch">
                                    <div class="callus-bg-box">
                                        <div class="callus-bg-box-shadow"></div>
                                    </div>
                                    <div class="twm-hpage-6-getintouch-title">
                                        <div class="wt-title-small">Contacts</div>
                                        <h2 class="wt-title">
                                            Veuillez nous contacter en cas de besoin !
                                        </h2>
                                    </div>
                                    <div class="twm-hpage-6-callus">
                                        <div class="callus-icon">
                                            <i class="flaticon-phone"></i>
                                        </div>
                                        <div class="callus-content">
                                            <div class="callus-number">+(243) 971464803</div>
                                            <div class="callus-email">info@mosala.site</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="twm-hpage-6-subscribe-wrap">
                                    <div class="hpage-6-nw-form-corner-wrap">
                                        <div class="twm-hpage-6-subscribe">
                                            <h3 class="twm-sub-title">Abonnez-vous gratuitement</h3>
                                            <div class="twm-sub-discription">
                                                Inscrivez-vous dès maintenant à notre abonnement par courrier électronique
                                                pour recevoir des mises
                                                à jour sur les nouveaux emplois et formations.
                                            </div>
                                            <form>
                                                <div class="hpage-6-nw-form">
                                                    <input name="news-letter" class="form-control" placeholder="" readOnly
                                                        type="email">
                                                    <button class="hpage-6-nw-form-btn"><i
                                                            class="fa fa-paper-plane"></i></button>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="hpage-6-nw-form-corner"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <!-- SUBSCRIBE SECTION END -->

        </div>
        <!-- CONTENT END -->

        <!-- FOOTER START -->
        <footer-layout></footer-layout>
        <!-- FOOTER END -->
    </div>

    <div style="position: fixed; top: 50%; right: 10px; z-index: 999;" v-if="choices.length > 0">
        <button type="button" @click="triggerNext" class="site-button m-r5 shadow-double" data-aos="fade-right">
            Cliquez pour continuer <i class="feather-arrow-right animated-icon"></i>
        </button>
    </div>
</template>

<script>
import HeaderLayout from '../layouts/header.layout';
import FooterLayout from '../layouts/footer.layout';
export default {
    name: 'HomePage',
    components: {
        HeaderLayout,
        FooterLayout
    },
    data() {
        return {
            choices: [],
            domaines: [
                {
                    icon: 'flaticon-coding',
                    title: 'Informatique&télécom',
                    actived: false,
                },
                {
                    icon: 'fas fa-car-alt',
                    title: 'Transport',
                    actived: false,
                },
                {
                    icon: 'flaticon-healthcare',
                    title: 'Médical',
                    actived: false,
                },
                {
                    icon: 'flaticon-bars',
                    title: 'Marketing',
                    actived: false,
                },
                {
                    icon: 'flaticon-bank',
                    title: 'Banque & assurance',
                    actived: false,
                },
                {
                    icon: 'fas fa-building',
                    title: 'Architecture',
                    actived: false,
                },
                {
                    icon: 'flaticon-dashboard',
                    title: 'Comptabilité',
                    actived: false,
                },
                {
                    icon: 'flaticon-tower',
                    title: 'Electricité',
                    actived: false,
                },
                {
                    icon: 'fas fa-balance-scale',
                    title: 'Droit',
                    actived: false,
                },
                {
                    icon: 'fa fa-paint-brush',
                    title: 'Design & infographie',
                    actived: false
                },

                {
                    icon: 'fa fa-graduation-cap',
                    title: 'Education',
                    actived: false
                },
                {
                    icon: 'fas fa-plane',
                    title: 'Voyage',
                    actived: false
                },
                {
                    icon: 'fas fa-shopping-cart',
                    title: 'Commerce & distribution',
                    actived: false
                },
                {
                    icon: 'fas fa-laptop',
                    title: 'Service aux entreprises',
                    actived: false
                },
                {
                    icon: 'fas fa-tv',
                    title: 'Eletronique',
                    actived: false
                },
                {
                    icon: 'fas fa-lightbulb',
                    title: 'Etudes & conseils',
                    actived: false
                },
                {
                    icon: 'fas fa-shield-alt',
                    title: 'Sécurité physique',
                    actived: false
                }, {
                    icon: 'fas fa-asterisk',
                    title: 'Autres',
                    actived: false
                },
            ]
        }
    },
    mounted() {
        if ($('.sticky-header').length) {
            var sticky = new Waypoint.Sticky({
                element: jQuery('.sticky-header')
            });
        }
    },
    methods: {
        onSelectedChanged(event, data) {
            console.log('onchanged', data);
            let checked = event.target.checked;
            data.actived = checked;
            if (checked) {
                this.choices.push(data);
            }
            else {
                for (let i = 0; i < this.choices.length; i++) {
                    if (this.choices[i].title === data.title) {
                        this.choices.splice(i, 1);
                    }
                }
            }
        },
        triggerNext() {
            let parsedJson = JSON.stringify(this.choices);
            localStorage.setItem('domaines', parsedJson);
            this.$router.push({ name: 'subscription' });
        }
    },

}
</script>